import React from "react";
import styled from "styled-components";
import {device} from '../utilities/Breakpoints'

const ButtonWrapper = styled.button`
  padding: 0.8rem 1.9rem;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 1;
  margin-top: ${(props) => props.top? props.top : 0}rem;
  margin-bottom: ${(props) => props.bottom? props.bottom : 0}rem;
  margin-right: ${(props) => props.right? props.right : 0}rem;
  margin-left: ${(props) => props.left? props.left : 0}rem;


  @media ${device.mobile} {
    border-radius: 1.5px;
  }

  span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: var(--main-color);
    border-radius: 50%;

    transition: 0.5s;

    transform: translateY(150%);
    z-index: -1;
    &:nth-child(1) {
      left: calc((0-1) * 25%);
      transition-delay: calc((0 - 1) * 0.1s);
    }
    &:nth-child(2) {
      left: calc((1-1) * 25%);
      transition-delay: calc((1 - 1) * 0.1s);
    }
    &:nth-child(3) {
      left: calc((2-1) * 25%);
      transition-delay: calc((2 - 1) * 0.1s);
    }
    &:nth-child(4) {
      left: calc((3-1) * 25%);
      transition-delay: calc((3 - 1) * 0.1s);
    }
  }
  &:hover,
  &:active,
  &:focus {
    color: var(--main-bg);
    transition: all 0.6s;
    outline: none;

    span {
      transform: translateY(0) scale(2);
    }
  }
`;

const PrimaryWrapper = styled(ButtonWrapper)`
  color: var(--main-bg);
  background: var(--main-color);
  transition: all 1s;
  &:hover {
    background: none;
  }
  span {
    background-color:var(--main-color);
  }
`

function Button(props) {
  return (
    <ButtonWrapper
      top={props.top}
      bottom={props.bottom}
      right={props.right}
      left={props.left}
      as='a'
      href={props.link}
      target={props.tab}
    >
      {props.title}
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </ButtonWrapper>
  );
}

function PrimaryButton(props) {
  return (
    <PrimaryWrapper
      top={props.top}
      bottom={props.bottom}
      right={props.right}
      left={props.left}
      as='a'
      href={props.link}
      target={props.tab}
    >
      {props.title}
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </PrimaryWrapper>
  );
}

export{ Button,PrimaryButton};
