import React from "react";
import styled from "styled-components";

const MainTitleWrap = styled.h1`
  font-size: 3.6rem;
  color: var(--heading-color);
  font-style: var(--main-font);
  letter-spacing: 0.1rem;

  span {
    color: var(--main-color);
  }
`;

function MainTitle() {
  return (
    <MainTitleWrap>
      I'm Chim
      <span>dindu Emmanuel</span>
    </MainTitleWrap>
  );
}

export default MainTitle;
