import styled from "styled-components";

const Title = styled.h3`
  font-size: 2.3rem;
  font-family: var(--main-font);
  color: var(--heading-color);
  position: relative;
  display: inline-block;
  margin-bottom: ${(props) => props.bottom}rem;

  span {
    color: var(--main-color);
  }
  &:before {
    content: "";
    width: 3rem;
    height: 2px;
    background: var(--main-color);
    position: absolute;
    bottom: -0.5rem;
    transition: all 0.5s;
  }
  &:hover:before {
    transform: translateX(4rem);
  }
`;

const SecondTitle = styled(Title)`
  color: var(--main-bg);
  span {
    -webkit-text-stroke: 1px var(--main-bg);
    color: var(--main-bg);
  }
  &:before {
    background: var(--main-bg);
  }
`;

export { Title, SecondTitle };
