import React, { useState } from "react";
import styled from "styled-components";
import P from "../components/Paragraph";
import { PrimaryButton } from "../components/Button";
import Picture from "../assets/pi.jpg";
import { device } from "../utilities/Breakpoints";
import resume from "../assets/Resume-Chukwuka Chimdindu Emmanuel.pdf";
import placeholder from "../assets/pi-alt.jpg";
const AboutMeWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  @media ${device.tablet} {
    margin-top: 4rem;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }

  .left {
    margin-right: 2rem;
    text-align: justify;

    @media ${device.mobile} {
      margin-right: 0;
    }
  }
  .img-wrapper {
    position: relative;
    margin-right: 2rem;
    border-radius: 5px;

    @media ${device.mobile} {
      margin: auto;
      margin-top: 4rem;
    }

    img {
      max-width: 25rem;
      border-radius: 5px;
      @media ${device.tablet} {
        max-width: 20rem;
      }

      @media ${device.mobile} {
        max-width: 30rem;
      }
    }

    &:before {
      content: "";
      width: fill-available;
      height: fill-available;
      border: 1px solid var(--main-color);
      position: absolute;
      top: 1rem;
      left: 2rem;
      z-index: -1;
      border-radius: 5px;
    }
    &:after {
      // content: "";
      // width: fill-available;
      // height: fill-available;
      // background: var(--main-color);
      // position: absolute;
      // top: 0;
      // left: 0;
      // z-index: 1;
      // border-radius: 5px;
      // opacity: 0.5;
    }
  }
`;

function AboutMe() {
  const [loading, setLoading] = useState(true);

  return (
    <AboutMeWrap>
      <div className="left">
        <P>
          I am a design-minded, detail oriented web developer passionate about
          combining beautiful, structured codes with nice interfaces and
          flawless fuctionalities. I have experience developing and designing
          web interfaces from simple landng pages to multi-paged web
          applications. I always strive to create software that not only
          functions efficiently, but also provides
          <span> intuitive and perfect user experiences. </span>
        </P>
        <P>
          I love learning new and better ways to create seamless user
          experiences with clean and efficient codes. I consider work an
          outgoing education and I'm always looking for opportunities to work
          with those willing to share their knowledge as much as I want to
          learn.
        </P>
        <P>
          When I'm not in writing codes, I'm probably <span>reading</span>,
          watching anime or playing chess.
        </P>
        <PrimaryButton title="Resume" top="1.3" link={resume} tab="_blank" />
      </div>

      <div className="img-wrapper">
        <img
          src={loading ? placeholder : Picture}
          onLoad={() => {
            setLoading(false);
          }}
          alt="dindu"
        />
      </div>
    </AboutMeWrap>
  );
}

export default AboutMe;
