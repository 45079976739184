import React , {useState, useRef, useEffect}from "react";
import styled from "styled-components";
import P from "../components/Paragraph";
import { Title } from "../components/Title";
import { device } from "../utilities/Breakpoints";
import SocialIcons from "../components/SocialIcons";

const ContactBoxWrapper = styled.div`
  background: var(--sub-bg);
  padding: 2rem;
  border-radius: 5px;
  max-width: 30vw;
  //box-shadow: 2px 2px 20px #000000;
  position: relative;
  transform: translate(-30%, 10vh);
  margin: auto;

  .status-box{
    max-width: 350px;
    position: absolute;
    margin: auto;
    padding: 1.5rem;
    font-size: 1.18rem;
    top:50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: var(--main-bg);
    visibility: hidden;
    transition:all 0.6s;
    color: transperent;

    &.success {
      color: green;
      visibility: visible;
    }
    &.error {
      color:red;
      visibility: visible;
    }
  }
  @media ${device.tablet} {
    max-width: 27rem;
    transform: translate(-30%, 18vh);
  }

  @media ${device.mobile} {
    transform: translate(-38%, 12vw);
    max-width: 27rem;
  }
  @media only screen and (max-width: 680px) {
    max-width: 32vw;
    //transform: translate(-30%, 18vh);
  }
  @media ${device.mobileS} {
    transform: translate(0, 10vw);
    max-width: 100%;
    margin-bottom: 45rem;
    /box-shadow: 2px 2px 20px #000000;
    z-index: 1;
  }

  p {
    width: 80%;

    @media ${device.mobileS} {
      width: 100%;
    }
  }

  .form {
    background: var(--sub-bg);
    padding: 2rem;
    max-width: 27vw;
    border-radius: 5px;
    box-shadow: 2px 2px 20px #000000;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(80%, -50%);

    @media ${device.mobile} {
      max-width: 25rem;
    }
    @media only screen and (max-width: 680px) {
      max-width: 30vw;
      //transform: translate(-30%, 18vh);
    }

    @media ${device.mobileS} {
      max-width: 100%;
      left: 0;
      right: auto;
      top: 100%;
      transform: translate(0, -5%);
      box-shadow: none;
    }
  }
`;

const Form = styled.form`
  width: 100%;

  input,
  textarea {
    width: 100%;
    background: transparent;
    font-size: var(--p-font) !important;
    font-family: var(--main-font);
    box-sizing: border-box;
    padding: 0.9rem;
    border-radius: 3px;
    border: 2px solid var(--text-color);
    color: var(--main-color);

    &:hover,
    &:focus {
      outline: none;
      border: 2px solid var(--main-color);
    }
  }

  input {
    &:nth-child(2) {
      margin: 2rem 0;
    }
  }
  button {
    padding: 0.8rem 1.9rem;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    background: none;
    border-radius: 3px;
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
    overflow: hidden;
    display: inline-block;
    text-decoration: none;
    position: relative;
    z-index: 1;
    margin-top: 2.5rem;
    transitin: all 0.6s;

    &:hover, &:active, &:focus {
      outline: none;
      background-color: var(--main-color);
      color: var(--main-bg)
    }
  }
`;

function ContactBox() {
  const [status, setStatus] = useState('')
  const box = useRef()

  const submitForm = (e) => {
    e.preventDefault();
    const form = e.target
    const data = new FormData(form);
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !==XMLHttpRequest.DONE) return
      if (xhr.status === 200){
        form.reset();
        setStatus('SUCCESS');
       
      } else {
        setStatus('ERROR');
      }
    }
    xhr.send(data)
  }

  useEffect(() => {
    if (status === 'SUCCESS'){
      box.current.classList.add('success')
      box.current.innerHTML = `Thanks, I got your message and i'll send you a response shortly`;
      setTimeout(()=> {box.current.classList.remove('success')} , 4000)
    } if(status === 'ERROR') {
      box.current.classList.add('error')
      box.current.innerHTML = `Opps something went wrong, could you submit again?`;
      setTimeout(()=> {box.current.classList.remove('error')} , 4000)
    }
  }, [status])
  
  return (
    <ContactBoxWrapper>
      <div className="status-box" ref={box}>
        
      </div>

      <Title bottom="2">
        Reach <span>Me</span>
      </Title>

      <P>
        I am open to freelancing roles, contract agreement and full 
        time roles in any company and project that would require my skillset.
        Got an idea?! hit me up so we can build something awesome.
      </P>
      <P bottom="2">You can also just reach out through any of my social media handles to say Hi, I 
      love to talk about tech and intresting stuff too</P>
      <SocialIcons />

      <div className="form">
        <Form action="https://formspree.io/f/myybjbyn" method='POST' onSubmit={(e)=>{submitForm(e)}}>
          <input type="text" name="name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email Address" />
          <textarea
            name="message"
            cols="30"
            placeholder="Message"
            rows="10"
          ></textarea>
          <button>Send</button>
        </Form>
      </div>
    </ContactBoxWrapper>
  );
}

export default ContactBox;
