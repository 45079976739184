import styled from "styled-components";
import { device } from "../utilities/Breakpoints";

const ButtonCover = styled.div`
  position: relative;
  width: max-content;

  @media ${device.mobile} {
    margin: auto;
  }
`;

export default ButtonCover;
