import styled from "styled-components";
import { device } from "../utilities/Breakpoints";

const NavbarWrap = styled.nav`
  // ================================
  // Main navigation
  // ================================

  nav {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: -100%;
    position: fixed;
    transition: left 0s calc(250ms * 2), transform 0s calc(250ms * 2);
    width: 100%;
    z-index: 10;

    &::before,
    &::after {
      content: "";
      background-color: var(--sub-bg);
      height: 50%;
      left: 0;
      position: absolute;
      transform: translateX(-110%);
      transform-origin: 0 50%;
      transition: transform calc(250ms * 2) 250ms
        cubic-bezier(0.215, 0.61, 0.355, 1);
      width: 100%;
      z-index: 10;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
    li {
      opacity: 0;
      transform: translateX(-1rem);
      transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1),
        transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  // ================================
  // Main navigation toggle
  // ================================

  label {
    position: fixed;
    top: -100%;
    cursor: pointer;
    position: fixed;
    right: var(--padding);
    top: 5rem;
    z-index: 100;
    display: flex;
    width: 5rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color);
    box-shadow: 1px 1px 10px #000000;

    @media ${device.mobileS} {
      top: 4rem;
    }

    span {
      display: inline-block;
      width: 3rem;
      height: 4px;
      border-radius: 0.2px;
      background-color: var(--main-bg);
      transition: all 0.5s;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        transition: all 0.5s;
        background-color: var(--main-bg);
        transform: translateY(0) translateX(0);
        height: 4px;
      }

      &::before {
        transform: rotate(0);
        top: 1.6rem;
        width: 3rem;
      }

      &::after {
        transform: rotate(0);
        bottom: 1.6rem;
        width: 3rem;
      }
    }
  }

  label.open {
    span {
      transform: rotate(1turn);
      width: 3rem;
      background: none;

      &::before {
        @media ${device.desktopL} {
          transform: rotate(-45deg) translateY(-19px) translateX(17px);
        }
        transform: rotate(-45deg) translateY(-15px) translateX(13px);
        width: 3rem;

        @media ${device.tablet} {
          transform: rotate(-45deg) translateY(-12px) translateX(11px);
        }

        @media ${device.mobile} {
          transform: rotate(-45deg) translateY(-10px) translateX(9px);
        }

        
      }

      &::after {
        @media ${device.desktopL} {
          transform: rotate(45deg) translateY(18px) translateX(18px);
        }

        transform: rotate(45deg) translateY(15px) translateX(14px);
        width: 3rem;

        @media ${device.tablet} {
          transform: rotate(45deg) translateY(13px) translateX(11px);
        }
        @media ${device.mobile} {
          transform: rotate(45deg) translateY(12px) translateX(11px);
        }

       
      }
    }
  }

  label.open ~ nav {
    left: 0;
    transition: transform 0s;

    &::before,
    &::after {
      transform: translateX(0);
      transition-delay: 0s;
    }

    &::after {
      transition-delay: calc(250ms / 2);
    }

    li {
      opacity: 1;
      transform: translateX(0);
      transition: opacity calc(250ms * 2) cubic-bezier(0.215, 0.61, 0.355, 1),
        transform calc(250ms * 2) cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    @for $i from 1 through 5 {
      li:nth-child(#{$i}) {
        transition-delay: calc(250ms * 2 * (#{$i} * 0.25));
        z-index: $i * -1;
      }
    }
  }

  // ================================
  // Menu
  // ================================

  ul {
    position: relative;
    text-align: center;
    z-index: 14;
    padding-left: 0;

    > li {
      font-family: var(--font-family-secondary);
      font-size: 10vmin;
    }
  }

  // ================================
  // Menu item
  // ================================

  li {
    display: block;
    position: relative;

    &:hover a,
    a.selected {
      &::before,
      &::after {
        animation: blink 1s 250ms steps(1, end) forwards infinite;
        transform: translateX(calc(100% - 0.5rem));
        transition-duration: calc(250ms * 3);
      }

      &::after {
        transition-delay: calc(250ms / 2);
      }
    }
  }

  // ================================
  // Menu link
  // ================================

  a {
    color: var(--text-color);
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    padding: 0.5rem 1rem 0.125rem;
    position: relative;
    text-decoration: none;
    transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 1;

    &::before,
    &::after {
      content: "";
      background-color: var(--main-color);
      height: 50%;
      left: 0;
      position: absolute;
      transform: translateX(-110%);
      transform-origin: 0 50%;
      transition: transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
      width: 100%;
      z-index: -1;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  // ================================
  // Menu item hover styles
  // ================================

  ul:not(:focus-within):not(:hover) li {
    a {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  li {
    --pull: 30%;

    a {
      opacity: 0.25;
      transition-duration: calc(250ms * 3);
      transform: translate(0, calc(var(--pull) * -1));
    }
    a.selected {
      color: var(--main-color);
    }
    &:hover > a {
      color: var(--main-color);
      opacity: 1;
      transform: translate(0, 0);

      &:hover {
        color: var(--main-color);
        transition-delay: 0s;
      }
    }

    &:hover ~ li > a {
      transition-duration: calc(250ms * 3);
      transform: translate(0, var(--pull));
    }
  }

  // ================================
  // Animations
  // ================================

  @keyframes blink {
    50%,
    100% {
      opacity: 0;
    }
  }
`;

export default NavbarWrap;
