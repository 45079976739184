import React, {useRef, useEffect} from "react";
import PageWrap from "../components/PageWrap";
import HomeIntro from "../parts/HomeIntro";
import Social from "../parts/Social";
import FooterWrap from "../components/FooterWrap";
import Logo from "../components/Logo";

//Gsap
import {TimelineLite,TweenMax, Power3} from 'gsap'

function Home() {

  let home = useRef()

  useEffect(() => {
    let tl = new TimelineLite()

    const lineBar = home.current.firstElementChild;
    const footer = home.current.lastChild;

    TweenMax.to(home.current, .1, {css:{visibility:'visible'}})

    tl.from(lineBar, .6, {opacity:0, y: -5, ease:Power3.easeOut}, 2.8)
      .from(footer, .6, {y:10, opacity:0, ease:Power3.easeOut}, 2.8)

  }, [])

  return (
    <PageWrap ref={home}>
      <Logo />
      <HomeIntro />
      <Social />
      <FooterWrap>
        Designed by <span>Dindu5 </span> All Rights Reserved &#169; 2020
      </FooterWrap>
    </PageWrap>
  );

}

export default Home;
