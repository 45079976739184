import React from "react";
import styled from "styled-components";
import P from "../components/Paragraph";
import { SubTitle } from "../components/SubTitle";
import Tag from "../components/Tag";
import Web from "../assets/development.svg";
import Design from "../assets/designing.svg";
import { device } from "../utilities/Breakpoints";

const ServiceWrap = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20vh;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    max-width: 70vw;
    margin: auto;
    grid-row-gap: 5rem;
  }

  @media ${device.tablet} {
    grid-column-gap: 13vh;
  }
`;

const ServiceBox = styled.div`
  padding: 2rem;
  background: var(--sub-bg);
  z-index: 2;
  margin-bottom: 2rem;

  .top {
    margin-bottom: 2rem;

    .service-img {
      margin: auto;
      width: 19vw;
      margin-bottom: 2rem;

      @media ${device.mobile} {
        width: 36vw;
      }

      @media ${device.mobileS} {
        width: 50vw;
      }

      img {
        width: 100%;
        display: block;
        height: auto;
      }
    }
  }
  .bottom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
  }
`;

function ServiceBoxes() {
  return (
    <ServiceWrap>
      <ServiceBox>
        <div className="top">
          <div className="service-img">
            <img src={Web} alt="Web Development" />
          </div>
          <SubTitle>Front End Web Development</SubTitle>
        </div>
        <div className="middle">
          <P>
            Using the Front End Trio [HTML, CSS and JavaScript] with
            pre-processors and build tools such as <span>SCSS</span>, I
            efficently implement Web Designs along with its functionalities. I
            also make utilise other tools such as <span>Bootstrap</span> and
            <span>Material UI</span> to ensure i deliver responsive web pages. I
            equally use Front-End frameworks like
            <span>Vue JS (Nuxt, Gridsome)</span> or{" "}
            <span>React JS (Next, Gatsby)</span> to build component based Single
            Page Web Applications.
          </P>
        </div>

        <div className="bottom">
          <Tag title="HTML5 & (S)CSS" right="1" />
          <Tag title="JavaScript" right="1" />
          <Tag title="React JS" right="1" />
          <Tag title="Vue JS" right="1" />
          <Tag title="Bootstrap" right="1" />
          <Tag title="Material UI" right="1" />
          <Tag title="Vuetify" right="1" />
          <Tag title="GSAP" right="1" />
        </div>
      </ServiceBox>

      <ServiceBox>
        <div className="top">
          <div className="service-img">
            <img src={Design} alt="UI Design" />
          </div>
          <SubTitle>User Interface Designs</SubTitle>
        </div>
        <div className="middle">
          <P>
            Designing isn't just about what a product looks and feels like on
            the outside. Designing encompasses the internal fuctionality of a
            product as well as the overall user experience. Utilizing the web
            based designing tool <span>Figma</span>, i create iterfaces and
            experiencies that people can enjoy on all digital mediums.
          </P>
        </div>
        <div className="bottom">
          <Tag title="Figma" />
        </div>
      </ServiceBox>
    </ServiceWrap>
  );
}

export default ServiceBoxes;
