import React, {useEffect, useRef} from "react";
import {TimelineLite, Power3} from 'gsap'

import PageWrap from "../components/PageWrap";
import ContactBox from "../parts/ContactBox";
import FooterWrap from "../components/FooterWrap";
import Logo from "../components/Logo";

function Contact() {

  let contact = useRef()

  useEffect(() => {
    let tl = new TimelineLite()

    tl.to(contact.current, .3, {css:{visibility:'visible'}})
      .from(contact.current, .9, {x:-700,opacity:0, transformOrigin:'left',ease:Power3.easeOut},.3)
  }, [])

  return (
    <PageWrap ref={contact}>
      <Logo />
      <ContactBox />
      <FooterWrap>
        Designed by <span>Dindu5 </span> All Rights Reserved &#169; 2020
      </FooterWrap>
    </PageWrap>
  );
}

export default Contact;
