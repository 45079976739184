import React from "react";
import styled from "styled-components";
import { ImGithub, ImWhatsapp } from "react-icons/im";
import { MdMailOutline } from "react-icons/md";
import { FaFacebookMessenger } from "react-icons/fa";

const SocialWrapper = styled.div`
  margin: 1rem 0;
  a {
    margin-right: 1.5rem;
    color: var(--main-color);
    svg {
      font-size: 2rem;

      transition: all 0.6s;
      &:hover,
      &:focus {
        transform: translateY(-5px) scale(1.2) rotate(360deg);
        cursor: ;
      }
    }
  }
`;
function SocialIcons() {
  return (
    <SocialWrapper>
      <a href='https://github.com/Dindu5' target='blank'>
        <ImGithub />
      </a>
      <a href='mailto:chimdindue@gmail.com' target='blank'>
        <MdMailOutline />
      </a>
      <a href='https://m.me/chimdindu.emmanuel' target='blank'>
        <FaFacebookMessenger />
      </a>
      <a href='https://wa.me/2348109819775' target='blank'>
        <ImWhatsapp />
      </a>
    </SocialWrapper>
  );
}

export default SocialIcons;
