import React, {useRef, useEffect} from "react";
import {TimelineLite, Power3} from 'gsap'

import {Button, PrimaryButton } from "../components/Button";
import PageWrap from "../components/PageWrap";
import { Title } from "../components/Title";
import AboutMe from "../parts/AboutMe";
import Footer from "../parts/Footer";
import ServiceBoxes from "../parts/ServiceBoxes";
import Timeline from "../parts/Timeline";
import TitleWrap from "../parts/TitleWrap";
import ButtonCover from "../components/ButtonCover";

function About() {

  let about = useRef()
  let aboutSecond = useRef()

  useEffect(() => {
    let tl = new TimelineLite()

    tl.to(about.current, .3, {css:{visibility:'visible'}})
      .to(aboutSecond.current, .1, {css:{visibility:'visible'}})
      .staggerFrom(about.current.children, 0.4, {y: 10, opacity:0, ease:Power3.easeOut},.2)


  }, [])

  return (
    <div>
      <PageWrap none ref={about}>
        <Title>
          About <span>Me</span>
        </Title>
        <AboutMe />
        <Timeline />
      </PageWrap>

      <TitleWrap titleMain="My" titleSub="Services" content="Services" />

      <PageWrap none ref={aboutSecond}>
        
        <ServiceBoxes />

        <ButtonCover>
          <PrimaryButton title="Hire Me" top="2.5" bottom="0.6" right="3" link='/contact' />
          <Button title="Go to Works" top="2.5" bottom="0.6" link='/works' />
        </ButtonCover>

        <Footer />
      </PageWrap>
    </div>
  );
}

export default About;
