import React, { useRef, useEffect, useState } from "react";
import SwitchWrap from "./SwitchWrap";
import { themes } from "../utilities/Themes";


import { MdAddBox } from "react-icons/md"
import { FiSettings } from 'react-icons/fi'
import { BsCaretDownFill } from 'react-icons/bs'

function ThemeSwitcher() {
  const [switcher, setSwitcher] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('userTheme') ? localStorage.getItem('userTheme') : 'light');

  const switchTheme = (currentTheme) => {
    if (currentTheme === "red") {
      document.documentElement.style.setProperty(
        "--main-color",
        themes.red.mainColor
      );
      document.documentElement.style.setProperty(
        "--main-bg",
        themes.red.mainBg
      );
      document.documentElement.style.setProperty("--sub-bg", themes.red.subBg);
      document.documentElement.style.setProperty(
        "--text-color",
        themes.red.textColor
      );
      document.documentElement.style.setProperty(
        "--bg-grad",
        themes.red.bgGrad
      );
      document.documentElement.style.setProperty(
        "--bg-blend",
        themes.red.bgBlend
      );
    }
    if (currentTheme === "neon") {
      document.documentElement.style.setProperty(
        "--main-color",
        themes.neon.mainColor
      );
      document.documentElement.style.setProperty(
        "--main-bg",
        themes.neon.mainBg
      );
      document.documentElement.style.setProperty("--sub-bg", themes.neon.subBg);
      document.documentElement.style.setProperty(
        "--text-color",
        themes.neon.textColor
      );
      document.documentElement.style.setProperty(
        "--bg-grad",
        themes.neon.bgGrad
      );
      document.documentElement.style.setProperty(
        "--bg-blend",
        themes.neon.bgBlend
      );
    }
    if (currentTheme === "blue") {
      document.documentElement.style.setProperty(
        "--main-color",
        themes.blue.mainColor
      );
      document.documentElement.style.setProperty(
        "--main-bg",
        themes.blue.mainBg
      );
      document.documentElement.style.setProperty("--sub-bg", themes.blue.subBg);
      document.documentElement.style.setProperty(
        "--text-color",
        themes.blue.textColor
      );
      document.documentElement.style.setProperty(
        "--heading-color",
        themes.blue.headingColor
      );
      document.documentElement.style.setProperty(
        "--bg-grad",
        themes.blue.bgGrad
      );
      document.documentElement.style.setProperty(
        "--bg-blend",
        themes.blue.bgBlend
      );
    }
    if (currentTheme === "purple") {
      document.documentElement.style.setProperty(
        "--main-color",
        themes.purple.mainColor
      );
      document.documentElement.style.setProperty(
        "--main-bg",
        themes.purple.mainBg
      );
      document.documentElement.style.setProperty(
        "--sub-bg",
        themes.purple.subBg
      );
      document.documentElement.style.setProperty(
        "--text-color",
        themes.purple.textColor
      );
      document.documentElement.style.setProperty(
        "--heading-color",
        themes.purple.headingColor
      );
      document.documentElement.style.setProperty(
        "--bg-grad",
        themes.purple.bgGrad
      );
      document.documentElement.style.setProperty(
        "--bg-blend",
        themes.purple.bgBlend
      );
    }
    if (currentTheme === "light") {
      document.documentElement.style.setProperty(
        "--main-color",
        themes.light.mainColor
      );
      document.documentElement.style.setProperty(
        "--main-bg",
        themes.light.mainBg
      );
      document.documentElement.style.setProperty(
        "--sub-bg",
        themes.light.subBg
      );
      document.documentElement.style.setProperty(
        "--text-color",
        themes.light.textColor
      );
      document.documentElement.style.setProperty(
        "--heading-color",
        themes.light.headingColor
      );
      document.documentElement.style.setProperty(
        "--bg-grad",
        themes.light.bgGrad
      );
      document.documentElement.style.setProperty(
        "--bg-blend",
        themes.light.bgBlend
      );
    }

    localStorage.setItem('userTheme', currentTheme)
  };

  const button = useRef();
  const overlay = useRef();

  useEffect(() => {
    const buttonElement = button.current;
    const wrapper = buttonElement.nextSibling;
    const overlayElement = overlay.current;

    overlayElement.addEventListener("click", () => {
      setSwitcher(false);
    });

    if (switcher === true) {
      wrapper.classList.add("opened-nav");
      //buttonElement.innerHTML = `<BsChevronDown/>`;
      overlayElement.classList.add("on-overlay");
    } else {
      wrapper.classList.remove("opened-nav");
      //buttonElement.innerHTML = ``;
      overlayElement.classList.remove("on-overlay");
    }
  }, [switcher]);

  const list = useRef();

  useEffect(() => {
    const listElements = Array.from(list.current.children);
    listElements.forEach((listElement) => {
      listElement.classList.remove("active");
      const activeSwitcher = (currentTheme) => {
        if (currentTheme === listElement.id) {
          listElement.classList.add("active");
        }
      };
      activeSwitcher(currentTheme);
      switchTheme(currentTheme);
    });
  }, [currentTheme]);

  return (
    <SwitchWrap>
      <button
        className="cn-button"
        ref={button}
        id="cn-button"
        onClick={() => {
          setSwitcher(!switcher);
        }}
      >
        { switcher ? <BsCaretDownFill/>:<FiSettings />}
      </button>
      <div className="cn-wrapper" id="cn-wrapper">
        <ul ref={list}>
          <li>
            <div
              id="red"
              href="/about"
              onClick={() => {
                setCurrentTheme("red");
                setSwitcher(false);
              }}
              style={{color: '#1167b1'}}

            >
              <MdAddBox />
            </div>
          </li>
          <li>
            <div
              id="green"
              onClick={() => {
                setCurrentTheme("neon");
                setSwitcher(false);
              }}
              style={{color: '#10f8e8'}}
            >
              <MdAddBox />
            </div>
          </li>
          <li>
            <div
              id="purple"
              onClick={() => {
                setCurrentTheme("blue");
                setSwitcher(false);
              }}
              style={{color: '#617830'}}
            >
              <MdAddBox />
            </div>
          </li>
          <li>
            <div
              id="wheat"
              onClick={() => {
                setCurrentTheme("purple");
                setSwitcher(false);
              }}
              style={{color: '#303437'}}
            >
              <MdAddBox />
            </div>
          </li>
          <li>
            <div
              id="orange"
              onClick={() => {
                setCurrentTheme("light");
                setSwitcher(false);
              }}
              style={{color: '#f2aa4c'}}
            >
              <MdAddBox />
            </div>
          </li>
        </ul>
      </div>
      <div id="cn-overlay" ref={overlay} className="cn-overlay"></div>
    </SwitchWrap>
  );
}

export default ThemeSwitcher;
