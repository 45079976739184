import React from "react";
import styled from "styled-components";
import { SecondTitle } from "../components/Title";
import { device } from "../utilities/Breakpoints";

const Deco = styled.div`
  background: var(--main-color);
  padding: 3rem var(--padding);
  position: relative;

  &:after {
    content: "${(props) => props.content}";
    position: absolute;
    -webkit-text-stroke: 1px var(--text-color);
    color: transparent;
    font-size: 12rem;

    text-transform: uppercase;
    letter-spacing: 0.3rem;
    top: 5rem;
    left: var(--padding);
    z-index: 0;
    opacity: 0.6;
    @media ${device.tablet} {
      font-size: 13vw;
    }
   
  }
`;

function TitleWrap(props) {
  return (
    <Deco content={props.content}>
      <SecondTitle>
        {props.titleMain} <span>{props.titleSub}</span>
      </SecondTitle>
    </Deco>
  );
}

export default TitleWrap;
