import React from "react";
import styled from "styled-components";
import P from "../components/Paragraph";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { device } from "../utilities/Breakpoints";

const TagWrap = styled(P)`
  opacity: 0.8;
  position: relative;
  margin-left: 1.2rem;
  margin-right: ${(props) => props.right}rem;
  display: inline-block;
  font-weight:600;

  @media ${device.tablet} {
    margin-left: 1.7rem;
  }

  svg {
    position: absolute;
    left: -1.4rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-color);

    @media ${device.tablet} {
      left: -1.7rem;
    }
  }
`;

function Tag(props) {
  return (
    <TagWrap right={props.right}>
      {props.title}
      <FaArrowAltCircleRight />
    </TagWrap>
  );
}

export default Tag;
