import styled from "styled-components";

const SubTitle = styled.h3`
  font-size: 1.3rem;
  font-family: var(--main-font);
  color: var(--main-color);
  position: relative;
  display: inline-block;
  line-height: 2;
`;

const SubTitleLeft = styled(SubTitle)`
  position: relative;
  text-align: right;

  &:before {
    content: "";
    width: 2.5rem;
    height: 0.2rem;
    top: 50%;
    transform: translateY(-20%);
    left: -4rem;
    position: absolute;
    background: var(--main-color);
  }
`;

const SubTitleRight = styled(SubTitleLeft)`
  &:before {
    left: auto;
    right: -4rem;
  }
`;

export { SubTitle, SubTitleLeft, SubTitleRight };
