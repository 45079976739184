import styled from "styled-components";

const FooterWrap = styled.p`
  position: absolute;
  font-size: var(--p-font);
  line-height: 2;
  font-family: var(--main-font);
  color: var(--text-color);
  letter-spacing: 0.1rem;
  font-weight: 600;
  bottom: 3rem;
  left: var(--padding);

  span {
    color: var(--main-color);
  }
`;

export default FooterWrap;
