import styled, { css } from "styled-components";
import { device } from "../utilities/Breakpoints";

const PageWrap = styled.main`
  padding: var(--padding);
  min-height: 73vh;
  position: relative;
  visibility:hidden;

  @media ${device.desktopL} {
    padding: 10rem var(--padding);
  }

  @media ${device.tablet} {
    min-height: 80vh;
  }

  @media ${device.mobile} {
    min-height: 75vh;
  }

  @media ${device.mobileS} {
    padding-top: 10rem;
  }
  ${(props) =>
    props.none &&
    css`
      min-height: fit-content;
    `}
`;

export default PageWrap;
