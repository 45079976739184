import React, {useEffect, useRef} from "react";
import styled from "styled-components";

import { device } from "../utilities/Breakpoints";

import {TimelineLite, Power3} from 'gsap'

const SocialBar = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(2rem) rotate(90deg);
  right: 0rem;

  @media ${device.desktopL} {
    right: 9vw;
  }

  @media ${device.tablet} {
    right: -1.3rem;
  }
  @media ${device.mobileS} {
    right: -3.1rem;
  }

  &:before {
    content: "";
    height: 0.2rem;
    width: 2rem;
    background-color: var(--main-color);
    position: absolute;
    transform: translateX(-2.5rem) translateY(10px);

    @media ${device.tablet} {
      transform: translateX(-2.8rem) translateY(8px);
    }
    @media ${device.tablet} {
      transform: translateX(-3rem) translateY(5px);
    }
  }

  a {
    text-decoration: none;
    color: var(--text-color);
    font-size: var(--p-font);
    text-decoration: none;

    &:nth-child(2) {
      margin: 0 1.5rem;
    }
  }
`;

function Social() {

  let social = useRef()

  useEffect(() => {
    let tl = new TimelineLite()

    tl.from(social.current, .6, {opacity:0, y: -5, ease:Power3.easeOut}, 2.8)
      .staggerFrom(social.current.children, 1.6, {opacity:0, ease:Power3.easeOut , delay: .5}, .3)

  }, [])

  return (
    <SocialBar ref={social}>
      <a href="https://www.facebook.com/chimdindu.emmanuel" target='blank'>Facebook</a>
      <a href="https://www.linkedin.com/in/chimdindu-chukwuka-1a0747158" target='blank'>LinkedIn</a>
      <a href="https://twitter.com/ChimdinduE?s=09" target='blank'>Twitter</a>
    </SocialBar>
  );
}

export default Social;
