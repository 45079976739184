import React from "react";
import styled from "styled-components";
import P from "../components/Paragraph";
import {device} from '../utilities/Breakpoints'

const FooterWrap = styled.div`
  max-width: max-content;
  margin: auto;
  margin-top: 3rem;
  position: static;
  bottom: 0;
  margin-bottom: -3rem;

  .img {
    margin-bottom: 1rem;
    margin: auto;
    width: max-content;
    
    svg {
      width: 5rem;
    }
  }
  p{
    @media ${device.mobileS} {
      margin-bottom: 3rem;
      text-align: auto;
    }
  }
`;

function Footer() {
  return (
    <FooterWrap>
      <div className="img">
      <svg width="69" height="60" viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.92 41V18.8H15.31C17.31 18.8 18.87 19.27 19.99 20.21C21.13 21.15 21.92 22.45 22.36 24.11C22.82 25.77 23.05 27.7 23.05 29.9C23.05 33.64 22.41 36.43 21.13 38.27C19.85 40.09 17.91 41 15.31 41H5.92ZM10.36 37.01H14.86C16.24 37.01 17.21 36.41 17.77 35.21C18.33 34.01 18.61 32.24 18.61 29.9C18.61 27.98 18.47 26.51 18.19 25.49C17.91 24.45 17.49 23.74 16.93 23.36C16.39 22.98 15.7 22.79 14.86 22.79H10.36V37.01Z" fill="var(--main-color)"/>
        <rect x="27" y="10" width="28" height="38" rx="2" fill="var(--main-color)" stroke="var(--main-color)" />
        <path d="M40.42 41.15C39.2 41.15 37.91 41.1 36.55 41C35.21 40.9 33.95 40.79 32.77 40.67V37.16H40.6C41.68 37.16 42.42 36.9 42.82 36.38C43.22 35.86 43.42 35.28 43.42 34.64V33.11C43.42 32.41 43.24 31.86 42.88 31.46C42.54 31.04 41.96 30.83 41.14 30.83H39.4C38.74 30.83 38.24 30.97 37.9 31.25C37.58 31.51 37.37 31.92 37.27 32.48H33.07L33.7 18.8H47.02V22.79H37.51L37.27 28.07C37.91 27.41 38.92 27.08 40.3 27.08H42.73C44.45 27.08 45.74 27.58 46.6 28.58C47.46 29.58 47.89 30.82 47.89 32.3V35.57C47.89 37.53 47.25 38.95 45.97 39.83C44.69 40.71 42.84 41.15 40.42 41.15Z" fill="var(--sub-bg)"/>
      </svg>
      </div>
      <P>
        Designed & Implemented by <span>Dindu5</span> All Righs Reserved &#169;
        2020
      </P>
    </FooterWrap>
  );
}

export default Footer;
