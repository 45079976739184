import React ,{useState} from "react";
import styled from "styled-components";
import placeholder from '../assets/gif4-alt.jpg'

import { device } from "../utilities/Breakpoints";

const WorksLeft = styled.div`
  height: max-content;
  padding: 1.5rem;
  width: 10rem;
  position: relative;
  border: 0.2rem solid var(--main-color);
  border-radius: 5px;
  margin-right: auto;
  

  @media ${device.mobileS} {
    border-radius: 2px;
  }

  div {
    width: 35vw;
    overflow:hidden;

    @media ${device.tablet} {
      width: 38vw;
    }

    @media ${device.mobile} {
      width: 60vw;
      border-radius: 3px;
    }

    @media ${device.mobileS} {
      width: 77vw;
      border-radius: 2px;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 3px;
    }
  }
`;

const WorksRight = styled.div`
  position: relative;
  margin-left: auto;

  @media ${device.mobile} {
    margin-top: 4rem;
  }

  div {
    width: 35vw;
    margin-right: 1.6rem;
    overflow:hidden;

    @media ${device.tablet} {
      width: 38vw;
    }

    @media ${device.mobile} {
      width: 60vw;
    }

    @media ${device.mobileS} {
      width: 80vw;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 3px;
      
    }
  }
  &:after {
    top: -1.5rem;
    bottom: -1.5rem;
    right: 0rem;
    width: 13rem;
    content: "";
    z-index: -1;
    position: absolute;
    border: 0.2rem solid var(--main-color);
    border-radius: 5px;

    @media ${device.mobile} {
      border-radius: 3px;
    }

    @media ${device.mobileS} {
      border-radius: 2px;
    }
  }
`;

function WorkImgLeft(props) {

  const [loading, setLoading] = useState(true)
  
  return (
    <WorksLeft >
      <div>
        <img 
          src={loading ? placeholder: props.img}
          onLoad={()=> {setLoading(false)}}
          alt="Projects" 
          style ={{opacity: loading? 0.95 : 1}}
        />
      </div>
    </WorksLeft>
  );
}
function WorkImgRight(props) {

  const [loading, setLoading] = useState(true)
  return (
    <WorksRight>
      <div>
        <img 
          src={loading ? placeholder: props.img}
          onLoad={()=> {setLoading(false)}}
          alt="Projects" 
        />
      </div>
    </WorksRight>
  );
}

export { WorkImgLeft, WorkImgRight };
