import React from "react";
import styled from "styled-components";
import { Button } from "../components/Button";
import P from "../components/Paragraph";
import Skills from "../components/Skills";
import { SubTitle } from "../components/SubTitle";
import { Title } from "../components/Title";
import { device } from "../utilities/Breakpoints";
import certificate from "../assets/Chimdindu Chukwuka Certificates.pdf";
const TimeWrap = styled.div`
  margin-top: 7rem;
`;

const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3rem;

  @media ${device.mobile} {
    flex-direction: column;
  }

  .certification {
    margin-left: 3rem;
    position: relative;
    width: max-content;

    &:before {
      content: "";
      width: 0.3rem;
      height: fill-available;
      border: 1px solid var(--main-color);
      border-radius: 5px;
      position: absolute;
      left: -2rem;
      top: 0;
    }
    .tab {
      width: 35rem;
      padding: 1rem 3rem;
      border: 1px solid var(--main-color);
      border-radius: 5px;
      margin-bottom: 4rem;
      margin-left: 1rem;
      margin-top: 1rem;
      position: relative;

      @media ${device.tablet} {
        width: 30vw;
        padding: 1rem 2rem;
      }
      @media ${device.mobile} {
        width: 35vw;
        padding: 1rem 2.5rem;
      }

      @media ${device.mobileS} {
        width: 57vw;
        padding: 1rem 2.5rem;
      }

      &:before {
        content: "";
        width: 1rem;
        height: 1rem;
        background: var(--main-color);
        border-radius: 50%;
        position: absolute;
        left: -3.3rem;
        top: 0;
      }
    }
  }
`;

function Timeline() {
  return (
    <TimeWrap>
      <Title>
        Timeline and <span>Certifications</span>
      </Title>
      <P top="3">Here are some of the places I have worked or interned at.</P>
      <ContentWrap>
        <div className="certification">
          <div className="tab">
            <P>January 2021</P>
            <SubTitle>Front-End Developer at Bento Africa</SubTitle>
            <P>
              I currently work as a front end developer at Bento Africa, a
              software company ofering seamless payroll services and HRM
              solutions. Some of my responsibilities include:
              <br />
              - Implementing web designs from figma files.
              <br />
              - Adopting the DRY (Don't Repeat Yourself) principle of
              development and building reusable components.
              <br />
              - Handling API integrations.
              <br />
              - Assisting in assessment of UX and UI designs for technical
              feasibility.
              <br />
              - Utilisation of best practices and approach during development.
              <br />
              - Collaborated with engineering team members in a fast-paced
              environment to achieve goals.
              <br />
            </P>
          </div>
          <div className="tab">
            <P>December, 2021 - January, 2022</P>
            <SubTitle>Front-End Developer at Buy Medix</SubTitle>
            <P>
              I worked as a contract staf to build out customer web interfaces
              for BuyMedix which is an online service ofering secure digital
              access to licensed pharmacies, medical practitioners, hospitals,
              labs, physiotherapists, original medical equipment providers and
              emergency/ ambulance services. I was responsible for the
              following:
              <br />
              - Implementing already laid out figma designs for the web
              application.
              <br />- Completing API integrations for all interfaces.
            </P>
          </div>
          <div className="tab">
            <P>August, 2021 - Feb, 2022</P>
            <SubTitle>Front-End Developer at TrustBreed</SubTitle>
            <P>
              I worked as a contract developer for Trustbreed, where I carried
              out the following:
              <br />
              I refactored some of the codes on the existing codebase
              <br />
              Integrated REST APIs.
              <br />
              Carried out pixel perfect implementation of UI design and fow.
              <br />
              Created dynamic cloudinary image upload functionality
            </P>
          </div>
          <div className="tab">
            <P>December 2020</P>
            <SubTitle>
              Front-End Developer at Terrell Davies Enterprise
            </SubTitle>
            <P>
              I worked as a front end developer at Terrell Davies Enterprse
              during a short contract job that lasted for two weeks. I
              implemented already made UI designs and worked with multiple
              end-points to fully set up fuctionality for the website.
            </P>
          </div>
          <div className="tab">
            <P>May 2020 - August 2020</P>
            <SubTitle>Intern at HNG Internships</SubTitle>
            <P>
              I interned as a Front End Developer at HNG Internships for a
              duration of three - four months where I emerge a{" "}
              <span>finalist</span> after weeks of building standard web world
              applications, solving real world problems and engaging in assigned
              tasks.
            </P>
          </div>
          <div className="tab">
            <P>August 2020 - September 2020</P>
            <SubTitle>Engineering Careers Expo 2.0</SubTitle>
            <P>
              I also interned as a Front End Developer at ECX 2.0 for about four
              weeks. There, we worked together in groups to completely weekly
              assigned tasks. At the end of the internship, a certificate was
              also issued to me.
            </P>
          </div>
          <Button
            link={certificate}
            title="Certifications"
            tab="blank"
            download
          />
        </div>
        <Skills />
      </ContentWrap>
    </TimeWrap>
  );
}

export default Timeline;
