import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Works from "./pages/Works";
import NotFound from "./pages/NotFound";

import Nav from "./components/Nav";
import ThemeSwitcher from "./components/ThemeSwitcher";

import { Decoration } from "./components/Decoration";
import NavContextProvider from "./context/NavContext";

function App() {
  return (
    <div>
      <NavContextProvider>
        <Router>
          <Decoration />

          <Nav />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/works" component={Works} />
            <Route path="/contact" component={Contact} />
            <Route path="*" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Router>
        <ThemeSwitcher />
      </NavContextProvider>
    </div>
  );
}

export default App;
