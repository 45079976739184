import React, {useRef, useEffect} from 'react'
import {TimelineLite} from 'gsap'
import Logo from "../components/Logo";
import Nav from '../components/Nav'
import PageWrap from '../components/PageWrap'
import FooterWrap from '../components/FooterWrap'
import Error from '../parts/Error'

function NotFound() {

    let error = useRef()
    
    useEffect(() => {
      let tl = new TimelineLite()
  
      tl.to(error.current, .3, {css:{visibility:'visible'}})
        
  
    }, [])
    return (
        <PageWrap ref={error}>
            <Logo />
            <Nav />
            <Error />
            <FooterWrap>
                Designed by <span>Dindu5 </span> All Rights Reserved &#169; 2020
            </FooterWrap>
        </PageWrap>            
        
    )
}

export default NotFound
