import React, { useRef, useEffect, useContext } from "react";
import styled from "styled-components";

//components

import MainTitle from "../components/MainTitle";
import { Button, PrimaryButton } from "../components/Button";
import SocialIcons from "../components/SocialIcons";
import { device } from "../utilities/Breakpoints";
import { NavContext } from "../context/NavContext";

//Gsap
import { gsap, TimelineLite, Power3, CSSPlugin } from "gsap/all";

gsap.registerPlugin(CSSPlugin);

const Intro = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-40%);
  max-width: 80%;

  @media ${device.mobile} {
    max-width: 77%;
  }

  .aside {
    width: 0.3rem;
    border: 1px solid var(--main-color);
    border-radius: 5px;
    margin-right: 1.8rem;
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: var(--p-font);
      line-height: 2;
      font-size: 1.4rem;
      color: var(--heading-color);
      letter-spacing: 0.1rem;

      span {
        color: var(--main-color);
      }
    }
  }

  &::before {
    content: "";
    // height: fill-available;
    // position: absolute;
    // width: 1rem;
    // border: 1px solid var(--main-color);
    // border-radius: 3;
  }
`;

function HomeIntro() {
  const { navigation, setNavigation } = useContext(NavContext);

  let intro = useRef();

  useEffect(() => {
    let tl = new TimelineLite();

    const lineBar = intro.current.firstElementChild;
    const mainBox = intro.current.lastChild;

    tl.from(
      lineBar,
      0.6,
      { y: -30, opacity: 0, ease: Power3.easeOut },
      1
    ).staggerFrom(
      mainBox.children,
      0.6,
      {
        opacity: 0,
        y: 20,
        transformOrigin: "bottom",
        overflow: "hidden",
        ease: Power3.easeOut,
      },
      0.2
    );
  }, []);

  return (
    <Intro ref={intro}>
      <div className="aside"></div>
      <div className="main">
        <p>Hi</p>
        <MainTitle />
        <p>
          <span>Front End Web</span> Developer
        </p>
        <SocialIcons />
        <div>
          <PrimaryButton
            title="Hire Me"
            top="0.6"
            bottom="0.6"
            right="1"
            link="/contact"
          />
          <span
            onClick={() => {
              setNavigation(!navigation);
            }}
          >
            <Button title="Explore" top="0.6" bottom="0.6" />
          </span>
        </div>
      </div>
    </Intro>
  );
}

export default HomeIntro;
