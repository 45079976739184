export const themes = {
  red: {
    mainColor: "#b3d8ee",
    mainBg: "#000000",
    subBg: '#003250',
    textColor: "#eefbfb",
    bgGrad: 'linear-gradient(125deg, #00FF57 0%, #010033 40%, #460043 70%, #F0FFC5 100%), linear-gradient(55deg, #0014C9 0%, #410060 100%), linear-gradient(300deg, #FFC700 0%, #001AFF 100%), radial-gradient(135% 215% at 115% 40%, #003e64 0%, #001928 40%, #004a77 calc(40% + 1px), #3396d2 60%, #66b0dd calc(60% + 1px), #EED690 80%, #ECEFD8 calc(80% + 1px), #001928 100%), linear-gradient(125deg, #282D4F 0%, #282D4F 40%, #23103A calc(40% + 1px), #23103A 70%, #003e64 calc(70% + 1px), #0070b3 88%, #002536 calc(88% + 1px), #00578b 100%)',
    bgBlend: 'soft-light, screen, darken, normal',
    bgBlend2: 'overlay, overlay, overlay, darken, normal',

    // mainColor: "#000000",
    // mainBg: "#007cc7",
    // subBg: '#4da8da',
    // textColor: "#eefbfb",

  },

  neon: {
    mainColor: "#10f8e8",
    mainBg: "#202020",
    subBg: "#021917",
    textColor: "#d5d5d5",
    bgGrad: 'linear-gradient(121deg, #021917 0%, #021917 100%), linear-gradient(140deg, #202020 27%, #021917 100%), linear-gradient(140deg, #202020 0%, #202020 72%), linear-gradient(64deg, #03322e 0%, #087c74 100%), radial-gradient(52% 101.79% at 50% 50%, #202020 0%, #0a958b 100%), radial-gradient(100% 100% at 70% 0%, #b7fdf8 0%, #b7fdf8 100%)',
    bgBlend: 'overlay, overlay, difference, difference, color-burn, exclusion',
    // mainColor: "#10f8e8",
    // mainBg: "#202020",
    // subBg: "#303030",
    // textColor: "#959595",
  },

  blue: {
    mainColor: "#68a850",
    mainBg: "#000000",
    subBg: "#00140c",
    textColor: "#feeee9",
    headingColor: "#d8e0e0",
    bgGrad: 'radial-gradient(100% 225% at 100% 0%, #00100a 0%, #00190e 100%), linear-gradient(35deg, #00100a 0%, #193d2f 75%), linear-gradient(55deg, #193d2f 0%, #180804 100%), linear-gradient(90deg, #000402 0%, #000402 40%, #913016 40%, #913016 50%, #60200f 50%, #60200f 70%, #180804 70%, #180804 100%), linear-gradient(180deg, #c1401e 0%, #c1401e 45%, #FBFF64 45%, #FBFF64 60%, #60200f 60%, #60200f 80%, #792813 80%, #792813 100%)',
    bgBlend: 'screen, overlay, overlay, darken, normal',
    // bgBlend: 'soft-light, screen, darken, normal',
    // bgGrad: 'radial-gradient(100% 225% at 100% 0%, #180804 0%, #301007 100%), linear-gradient(35deg, #60299f 0%, #48180b 75%), linear-gradient(55deg, #48180b 0%, #180804 100%), linear-gradient(90deg, #48190b 0%, #48190b 40%, #913016 40%, #913016 50%, #60200f 50%, #60200f 70%, #180804 70%, #180804 100%), linear-gradient(180deg, #c1401e 0%, #c1401e 45%, #FBFF64 45%, #FBFF64 60%, #60200f 60%, #60200f 80%, #792813 80%, #792813 100%)',
  },
  
  purple: {
    mainColor: "#001733",
    mainBg: "#fefefe",
    subBg: "#cbcbcb",
    textColor: "#181810",
    headingColor: "#ffffff",
    bgGrad: 'linear-gradient(123deg, #FFFFFF 0%, #e6f7ff 100%), linear-gradient(236deg, #f8fff5 0%, #fafafa 100%), linear-gradient(180deg, #FFFFFF 0%, #e6e6e6 100%), linear-gradient(225deg, #e6f4ff 20%, #f9fefd 45%, #eae7eb 45%, #bfb7c2 70%, #333333 70%, #333333 85%, #191919 85%, #191919 100%), linear-gradient(135deg, #191919 15%, #191919 35%, #141414 35%, #141414 60%, #171717 60%, #171717 68%, #d1d1d1 68%, #d1d1d1 100%)',
    bgBlend: 'overlay, overlay, overlay, darken, normal',
    // mainColor: "#302828",
    // mainBg: "#c8b088",
    // subBg: "#e0d9c0",
    // textColor: "#181810",
    // headingColor: "#ffffff",
    // bgGrad: 'linear-gradient(123deg, #FFFFFF 0%, #e6f7ff 100%), linear-gradient(236deg, #f8fff5 0%, #fafafa 100%), linear-gradient(180deg, #FFFFFF 0%, #e6e6e6 100%), linear-gradient(225deg, #e6f4ff 20%, #f9fefd 45%, #eae7eb 45%, #bfb7c2 70%, #333333 70%, #333333 85%, #191919 85%, #191919 100%), linear-gradient(135deg, #191919 15%, #191919 35%, #141414 35%, #141414 60%, #171717 60%, #171717 68%, #d1d1d1 68%, #d1d1d1 100%)',
  },
  light: {
    mainColor: "#f2aa4c",
    mainBg: "#181108",
    subBg: "#181108",
    textColor: "#fceedb",
    headingColor: "#ffffff",
    bgGrad: 'radial-gradient(100% 135% at 90% 0%, #0d131a 0%, #0e161d 33%, #404646 calc(33% + 1px), #40464d 75%, #5e5d63 calc(75% + 1px), #101820 100%), radial-gradient(circle at 60% 110%, #585d63 0%, #585d63 33%, #a97735 calc(33% + 1px), #a97735 66%, #101820 calc(66% + 1px), #101820 100%), radial-gradient(100% 225% at 0% 0%, #585d63 0%, #585d63 33%, #a97735 calc(33% + 1px), #a97735 66%, #101820 calc(66% + 1px), #101820 100%)',
    bgBlend: 'multiply, multiply, normal',

    // mainColor: "#f2aa4c",
    // mainBg: "#101820",
    // subBg: "#131f2c",
    // textColor: "#959595",
    // headingColor: "#ffffff",
  },
};
