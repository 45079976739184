import React, { useEffect, useRef, useContext } from "react";
import {TweenMax } from 'gsap'
import { NavLink } from "react-router-dom";

import NavbarWrap from "./NavbarWrap";
import {NavContext} from '../context/NavContext'

function Nav() {
  
  const {navigation, setNavigation} = useContext(NavContext)

  const links = useRef();
  const button = useRef();

  useEffect(() => {
    const linkElements = Array.from(links.current.children);
    linkElements.forEach((linkElement) => {
      linkElement.addEventListener("click", () => {
        setNavigation(false);
      });
    });

    if (navigation === true) {
      button.current.classList.add("open");
    } else {
      button.current.classList.remove("open");
    }
    
  }, [navigation,setNavigation]);

  useEffect(() => {

    TweenMax.from(button.current, .5, {scale:0, ease:'back.out', delay:3.4})
  }, [])

  return (
    <NavbarWrap>
      <label ref={button} onClick={() => setNavigation(!navigation)}>
        <span></span>
      </label>
      <nav>
        <ul ref={links}>
          <li>
            <NavLink activeClassName="selected" exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="selected" to="/about">
              About Me
            </NavLink>
          </li>

          <li>
            <NavLink activeClassName="selected" to="/works">
              My Works
            </NavLink>
          </li>

          <li>
            <NavLink activeClassName="selected" to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </NavbarWrap>
  );
}

export default Nav;
