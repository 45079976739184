import React from "react";
import styled, { css } from "styled-components";

import { WorkImgLeft, WorkImgRight } from "../components/WorkImgLeft";
import { DescriptionLeft, DescriptionRight } from "../components/Description";
import { SubTitleLeft, SubTitleRight } from "../components/SubTitle";
import Tag from "../components/Tag";
import { Button, PrimaryButton } from "../components/Button";
import { device } from "../utilities/Breakpoints";

import hotel from "../assets/hotelui.gif";
import mobile from "../assets/mobile.gif";
import gif1 from "../assets/gif1.gif";
import gif3 from "../assets/gif3.gif";
import gif4 from "../assets/gif4.gif";
import gif6 from "../assets/gif6.gif";
import gif7 from "../assets/gif7.gif";
import gif8 from "../assets/grand-hotel.gif";
import gif9 from "../assets/github-project.gif";
import gif10 from "../assets/talk.png";
import print from "../assets/print.jpg";
import cloud from "../assets/cloud.jpg";
import store from "../assets/store.jpg";

const WorksL = styled.div`
  margin-top: 9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${device.tablet} {
    margin-top: 10rem;
  }

  @media ${device.mobile} {
    margin-top: 6rem;
    flex-direction: column;
  }

  ${(props) =>
    props.top &&
    css`
      margin-top: 0;
    `}
  video {
    width: 20vw;
  }
  .second {
    margin-left: auto;
    text-align: right;
  }
`;

const WorksR = styled(WorksL)`
  @media ${device.mobile} {
    //flex-direction: column-reverse;
  }
  .second {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
  }
`;

function WorksNSix() {
  return (
    <WorksR>
      <div className="second">
        <SubTitleRight>Pog-Print</SubTitleRight>
        <DescriptionRight title="A full stack remote printing web service featuring both client and admin dashboards. I built this as my final year project, it gives users the ability to initiate print orders from their homes while adding optional services to the print orders. I believe this solution would make printing much easier and faster." />
        <div>
          <Tag title="React JS" right="1" />
          <Tag title="Tailwind CSS" right="1" />
          <Tag title="Strapi" right="1" />
          <Tag title="Cloudinary" right="1" />
          <Tag title="Mail Jet" right="1" />
          <Tag title="Paystack SDK" right="1" />
          <Tag title="Postgres DB" />
        </div>
        <div>
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://pog-print.netlify.app/"
            right="3"
            tab="blank"
          />
        </div>
      </div>
      <WorkImgRight img={print} />
    </WorksR>
  );
}

function WorksNFive() {
  return (
    <WorksL>
      <WorkImgLeft img={cloud} />
      <div className="second">
        <SubTitleLeft>C-Cloud</SubTitleLeft>
        <DescriptionLeft title="This is an online file storing / sharing web service where registered users can upload their files for storage and they can share these files with other registered users. I built this with Vue JS, Cloudinary, Strapi and Postgres DB. The backend codes have been hosted on Heroku and integrated with both cloudinary for static file storage" />
        <div>
          <Tag title="Vue JS" right="1" />
          <Tag title="Vuetify" right="1" />
          <Tag title="Cloudinary" right="1" />
          <Tag title="Strapi" right="1" />
          <Tag title="Postgres" />
        </div>
        <div>
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://c-cloud-share.netlify.app/login/"
            tab="blank"
          />
        </div>
      </div>
    </WorksL>
  );
}

function WorksNFour() {
  return (
    <WorksR>
      <div className="second">
        <SubTitleRight>Daniel Stores</SubTitleRight>
        <DescriptionRight title="This is an full stack ecommerce project built with React JS on the front end and strapi on the backend. I also added SnipCart for cart management and payment automation, tho the project is still in a test phase." />
        <div>
          <Tag title="React JS" right="1" />
          <Tag title="Strapi" right="1" />
          <Tag title="Postgres DB" right="1" />
          <Tag title="SnipCart" />
        </div>
        <div>
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://danny-stores.netlify.app/"
            tab="blank"
          />
        </div>
      </div>
      <WorkImgRight img={store} />
    </WorksR>
  );
}

function WorksNOne() {
  return (
    <WorksL>
      <WorkImgLeft img={gif8} />

      <div className="second">
        <SubTitleLeft>Grand Hotel Nova</SubTitleLeft>
        <DescriptionLeft
          title="Grand Hotel Nova offers all forms of luxury, 
        entertainment and pleasure. This fictional hotel was designed by
         @Groot and i implemented it. Its a 
         four paged hotel site built solely with react and styled 
         components to ensure proper code maintainance and scalability"
        />
        <div>
          <Tag title="React JS" right="1" />
          <Tag title="Styled-components" right="1" />
          <Tag title="GSAP" />
        </div>
        <div>
          <Button
            title="View Code"
            top="1.5"
            right="3"
            link="https://github.com/Dindu5/grand-hotel-react"
            tab="blank"
          />
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://grand-hotel-ui.netlify.app/"
            tab="blank"
          />
        </div>
      </div>
    </WorksL>
  );
}

function WorksNTwo() {
  return (
    <WorksR>
      <div className="second">
        <SubTitleRight>GitHub Profile Checker</SubTitleRight>
        <DescriptionRight
          title="A detailed github profile checker built with the 
        aid of the github API. it fetches user details and some repositories."
        />
        <div>
          <Tag title="React JS" right="1" />
          <Tag title="GitHub API" right="1" />
        </div>
        <div>
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://github-checker.netlify.app/"
            right="3"
            tab="blank"
          />
          <Button
            title="View Code"
            top="1.5"
            link="https://github.com/Dindu5/github-profile"
            tab="blank"
          />
        </div>
      </div>
      <WorkImgRight img={gif9} />
    </WorksR>
  );
}

function WorksOne() {
  return (
    <WorksL>
      <WorkImgLeft img={gif6} />

      <div className="second">
        <SubTitleLeft>Music - Artiste</SubTitleLeft>
        <DescriptionLeft
          title="This is a portfolio website template meant for a music 
          artiste. I implemented this design during one of my internships. 
          It feautures some light animations with the aid of GreenStock Animation Library (GSAP) 
          and CSS keyframes to improve its overall user experience"
        />
        <div>
          <Tag title="HTML" right="1" />
          <Tag title="CSS" right="1" />
          <Tag title="JavaScript" right="1" />
          <Tag title="Bootstrap" />
        </div>
        <div>
          <Button
            title="View Code"
            top="1.5"
            right="3"
            link="https://github.com/Dindu5/Music-Live"
            tab="blank"
          />
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://dindu-musiclive.netlify.app/"
            tab="blank"
          />
        </div>
      </div>
    </WorksL>
  );
}

function WorksTwo() {
  return (
    <WorksR>
      <div className="second">
        <SubTitleRight>Social - Ape</SubTitleRight>
        <DescriptionRight
          title="Social Ape is a social media web application that utilises 
          Google's Firebase to serve as the application's database. This 
          was a collaborative school project aimed at providing alternatives to existing 
          social media platforms."
        />
        <div>
          <Tag title="React" right="1" />
          <Tag title="FireBase" right="1" />
          <Tag title="Material UI" right="1" />
        </div>
        <div>
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://mysocialape-4d48f.firebaseapp.com/"
            right="3"
            tab="blank"
          />
          <Button
            title="View Code"
            top="1.5"
            link="https://mysocialape-4d48f.firebaseapp.com/"
            tab="blank"
          />
        </div>
      </div>
      <WorkImgRight img={gif3} />
    </WorksR>
  );
}

function WorksThree() {
  return (
    <WorksL>
      <WorkImgLeft img={gif1} />

      <div className="second">
        <SubTitleLeft>DentLab</SubTitleLeft>
        <DescriptionLeft
          title="This is a single page website for a 
          fictional company called DentLab that offer Dental health 
          services. The website has a simplified and yet inviting design. 
          I built this during one of my internships"
        />
        <div>
          <Tag title="HTML" right="1" />
          <Tag title="CSS" right="1" />
          <Tag title="JavaScript" right="1" />
          <Tag title="Bootstrap" right="1" />
        </div>
        <div>
          <Button
            title="View Code"
            top="1.5"
            right="3"
            link="https://github.com/dindu5/Envato"
            tab="blank"
          />
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://dindu5.github.io/Envato"
            tab="blank"
          />
        </div>
      </div>
    </WorksL>
  );
}

function WorksFour() {
  return (
    <WorksR>
      <div className="second">
        <SubTitleRight>Theme - Switcher</SubTitleRight>
        <DescriptionRight
          title="After thinking of various ways of implementing multiple 
          themes in React. I came up this method that lets me
          use the much beloved styled-components and css variables."
        />
        <div>
          <Tag title="React" right="1" />
        </div>
        <div>
          <PrimaryButton
            right="3"
            title="Live Project"
            top="1.5"
            link="https://my-theme-switcher.netlify.app/"
            tab="blank"
          />
          <Button
            title="View Code"
            top="1.5"
            link="https://github.com/Dindu5/react-theme"
            tab="blank"
          />
        </div>
      </div>
      <WorkImgRight img={gif7} />
    </WorksR>
  );
}

function WorksFive() {
  return (
    <WorksL>
      <WorkImgLeft img={gif4} />

      <div className="second">
        <SubTitleLeft>A+ Tutors</SubTitleLeft>
        <DescriptionLeft
          title="This was designed to be an online educational / learning 
          platform for students preparing for national level 
          examinations such as WAEC / NECO. It was a group project 
          during one of my internships and i played a key role in 
          implementing the design"
        />
        <div>
          <Tag title="HTML" right="1" />
          <Tag title="CSS" right="1" />
          <Tag title="JavaScript" right="1" />
          <Tag title="Bootstrap" right="1" />
        </div>
        <div>
          <Button
            title="View Code"
            top="1.5"
            right="3"
            link="https://github.com/Dindu5/Aplus-Tutors"
            tab="blank"
          />
          <PrimaryButton
            title="Live Project"
            top="1.5"
            link="https://team25-aplus-tutors.netlify.app/"
            tab="_blank"
          />
        </div>
      </div>
    </WorksL>
  );
}

function WorksNThree() {
  return (
    <WorksR>
      <div className="second">
        <SubTitleRight>Talku - Talku</SubTitleRight>
        <DescriptionRight
          title="Talku Talku was one of the projects i worked on during my ECX Internship. 
          It serves to help registered users learn any language of their choice. This was a group project."
        />
        <div>
          <Tag title="HTML5" right="1" />
          <Tag title="CSS" right="1" />
          <Tag title="JS" right="1" />
          <Tag title="Bootstrap" right="1" />
        </div>
        <div>
          <PrimaryButton
            right="3"
            title="Live Project"
            top="1.5"
            link="https://team6-week3.netlify.app/"
            tab="blank"
          />
          <Button
            title="View Code"
            top="1.5"
            link="https://github.com/Dindu5/Team-6-Week3"
            tab="blank"
          />
        </div>
      </div>
      <WorkImgRight img={gif10} />
    </WorksR>
  );
}

//               //
// Design Works
//               //
function WorksDOne() {
  return (
    <WorksL top>
      <WorkImgLeft img={hotel} />

      <div className="second">
        <SubTitleLeft>Hotel Translovenia</SubTitleLeft>
        <DescriptionLeft
          title="A UI Design for a fictional hotel somewhere in Nigeria. With just four pages, it was one of my
          internship tasks at hotels.ng"
        />
        <div>
          <Tag title="Figma" right="1" />
        </div>

        <Button
          title="View Prototype"
          top="1.5"
          link="https://www.figma.com/proto/xPz0WRbsPD87vFV1JJtrhf/Hotel-UI?node-id=1%3A2&scaling=min-zoom"
          tab="_blank"
        />
      </div>
    </WorksL>
  );
}

function WorksDTwo() {
  return (
    <WorksR>
      <div>
        <SubTitleRight>Metusella</SubTitleRight>
        <DescriptionRight
          title="Named after the oldest man recorded in the bible, Metusella is an app
          that helps a user live long and healthy enough through provision of health tips and recommendations
          based on a users current medical records. This is an idea for mobile application."
        />
        <div>
          <Tag title="Figma" right="1" />
        </div>
        <div>
          <Button
            title="View Prototype"
            link="https://www.figma.com/proto/wkrjps2kLzL0wUzPxbDBqu/Metusella?node-id=1%3A2&scaling=scale-down"
            top="1.5"
            tab="_blank"
          />
        </div>
      </div>
      <WorkImgRight img={mobile} />
    </WorksR>
  );
}

// function WorksDThree() {
//   return (
//     <WorksL>
//       <WorkImgLeft img={hotel} />

//       <div className="second">
//         <SubTitleLeft>Photograpy - Portfolio</SubTitleLeft>
//         <DescriptionLeft
//           title="A single page portfolio user interface design for a freelance photographer."
//         />
//         <div>
//           <Tag title="Figma" right="1" />
//         </div>
//         <div>
//           <Button title="View Prototype" top="1.5" />
//         </div>
//       </div>
//     </WorksL>
//   );
// }

export {
  WorksOne,
  WorksTwo,
  WorksThree,
  WorksFour,
  WorksFive,
  WorksDOne,
  WorksDTwo,
  WorksNOne,
  WorksNTwo,
  WorksNThree,
  WorksNFour,
  WorksNFive,
  WorksNSix,
};
