import React from "react";
import styled from "styled-components";
import P from "./Paragraph";
import { device } from "../utilities/Breakpoints";

const DescriptionRightWrapper = styled.div`
  padding: 1rem 1.5rem;
  width: 30vw;
  margin: 1.5rem 0;
  background: var(--sub-bg);
  border-radius: 3px;
  text-align: left;
  //margin-right: auto;

  @media ${device.tablet} {
    width: 32vw;
  }

  @media ${device.mobile} {
    width: 60vw;
  }
  @media ${device.mobileS} {
    width: 70vw;
  }
`;

const DescriptionLeftWrapper = styled(DescriptionRightWrapper)`
  text-align: right;
  //margin-left: auto;
`;

function DescriptionRight(props) {
  return (
    <DescriptionRightWrapper>
      <P>{props.title}</P>
    </DescriptionRightWrapper>
  );
}
function DescriptionLeft(props) {
  return (
    <DescriptionLeftWrapper>
      <P>{props.title}</P>
    </DescriptionLeftWrapper>
  );
}

export { DescriptionRight, DescriptionLeft };
