import React, { useRef, useEffect } from "react";
import { TimelineLite, Power3, gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

// Page Imports
import PageWrap from "../components/PageWrap";
import { Title } from "../components/Title";
import Footer from "../parts/Footer";
import TitleWrap from "../parts/TitleWrap";
import {
  WorksFive,
  WorksOne,
  WorksFour,
  WorksThree,
  WorksTwo,
  WorksDOne,
  WorksDTwo,
  WorksNOne,
  WorksNTwo,
  WorksNThree,
  WorksNFive,
  WorksNFour,
  WorksNSix,
} from "../parts/WorksOne";

function Works() {
  // Refs
  let works = useRef();
  let worksSecond = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.core.globals("ScrollTrigger", ScrollTrigger);

    // Variable Declearation

    let tl = new TimelineLite();
    let images = Array.from(works.current.children);
    let designImages = Array.from(worksSecond.current.children);
    const [imageD, imageS] = designImages;

    let [imageOne, imageTwo, imageThree, imageFour, imageFive, imageSix] =
      images;

    let designElements = [
      imageS.lastElementChild.firstElementChild.firstElementChild,
      imageThree.lastElementChild.firstElementChild.firstElementChild,
      imageFive.lastElementChild.firstElementChild.firstElementChild,
    ];

    let newElements = [
      imageD.firstElementChild.firstElementChild.firstElementChild,
      imageTwo.firstElementChild.firstElementChild.firstElementChild,
      imageFour.firstElementChild.firstElementChild.firstElementChild,
      imageSix.firstElementChild.firstElementChild.firstElementChild,
    ];

    // Sliding Gsap Animation [left]

    newElements.forEach((el) => {
      gsap.from(el, 1.2, {
        x: -550,
        transformOrigin: "left",
        ease: Power3.easeOut,

        scrollTrigger: {
          trigger: el,
          start: "top center+=150",
          toggleActions: "play none none reverse",
        },
      });
    });

    designElements.forEach((el) => {
      gsap.from(el, 1.2, {
        x: 550,
        transformOrigin: "right",
        ease: Power3.easeOut,
        scrollTrigger: {
          trigger: el,
          start: "top center+=150",
          toggleActions: "play none none reverse",
        },
      });
    });

    tl.to(works.current, 0.3, { css: { visibility: "visible" } })
      .to(worksSecond.current, 0.1, { css: { visibility: "visible" } })
      .staggerFrom(
        works.current.children,
        0.5,
        { y: 20, opacity: 0, ease: "back" },
        0.2
      );

    console.log(imageOne);
  }, []);

  return (
    <>
      <PageWrap ref={works}>
        <Title>
          Some <span>Projects</span>
        </Title>
        <WorksNSix />
        <WorksNFive />
        <WorksNFour />
        <WorksNOne />
        <WorksNTwo />
        <WorksOne />
        <WorksTwo />
        <WorksThree />
        <WorksFour />
        <WorksFive />
        <WorksNThree />
      </PageWrap>
      <TitleWrap titleMain="Design" titleSub="Works" content="UI Designs" />
      <PageWrap ref={worksSecond}>
        <WorksDOne />
        <WorksDTwo />
        <Footer />
      </PageWrap>
    </>
  );
}

export default Works;
