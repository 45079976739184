import styled, {keyframes} from "styled-components";
import { device } from "../utilities/Breakpoints";

const hover =  keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-7px);
  }
`;

const SwitchWrapper = styled.div`
  .cn-wrapper {
    font-size: 1em;
    width: 26em;
    height: 26em;

    position: fixed;
    z-index: 2;
    bottom: -16em;
    left: 50%;
    border-radius: 50%;
    margin-left: -13em;
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    transform: scale(0.1);
    pointer-events: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.opened-nav {
      border-radius: 50%;
      pointer-events: auto;
      bottom: 0em;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);

      @media ${device.mobileS} {
        transform: scale(1.3);
        bottom: 4em;
      }
    }

    li {
      position: absolute;
      font-size: 1.5em;
      width: 10em;
      height: 10em;
      -webkit-transform-origin: 100% 100%;
      -moz-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      overflow: hidden;
      left: 50%;
      top: 50%;
      margin-top: -1.3em;
      margin-left: -10em;
      -webkit-transition: border 0.3s ease;
      -moz-transition: border 0.3s ease;
      transition: border 0.3s ease;

      &:first-child {
        -webkit-transform: rotate(-10deg) skew(50deg);
        -ms-transform: rotate(-10deg) skew(50deg);
        -moz-transform: rotate(-10deg) skew(50deg);
        transform: rotate(-10deg) skew(50deg);
      }

      &:nth-child(2) {
        -webkit-transform: rotate(30deg) skew(50deg);
        -ms-transform: rotate(30deg) skew(50deg);
        -moz-transform: rotate(30deg) skew(50deg);
        transform: rotate(30deg) skew(50deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(70deg) skew(50deg);
        -ms-transform: rotate(70deg) skew(50deg);
        -moz-transform: rotate(70deg) skew(50deg);
        transform: rotate(70deg) skew(50deg);
      }

      &:nth-child(4) {
        -webkit-transform: rotate(110deg) skew(50deg);
        -ms-transform: rotate(110deg) skew(50deg);
        -moz-transform: rotate(110deg) skew(50deg);
        transform: rotate(110deg) skew(50deg);
      }

      &:nth-child(5) {
        -webkit-transform: rotate(150deg) skew(50deg);
        -ms-transform: rotate(150deg) skew(50deg);
        -moz-transform: rotate(150deg) skew(50deg);
        transform: rotate(150deg) skew(50deg);
      }

      &:nth-child(odd) div {
        background-color: var(--sub-bg);
        opacity: 0.9;
      }

      &:nth-child(even) div {
        background-color: var(--sub-bg);
        opacity: 0.8;
      }

      div {
        display: block;
        font-size: 1.18em;
        height: 14.5em;
        width: 14.5em;
        position: absolute;
        bottom: -7.25em;
        right: -7.25em;
        border-radius: 50%;
        text-decoration: none;
        color: #fff;
        padding-top: 0em;
        text-align: center;
        -webkit-transform: skew(-50deg) rotate(-70deg) scale(1);
        -ms-transform: skew(-50deg) rotate(-70deg) scale(1);
        -moz-transform: skew(-50deg) rotate(-70deg) scale(1);
        transform: skew(-50deg) rotate(-70deg) scale(1);
        -webkit-backface-visibility: hidden;
        -webkit-transition: opacity 0.3s, color 0.3s;
        -moz-transition: opacity 0.3s, color 0.3s;
        transition: opacity 0.3s, color 0.3s;

        // Styling for inside element
        svg {
          font-size: 1.1em;
          padding: 2.5rem;
          &:nth-child(2){
            color: var(--main-color);
          }
        }
      }

      &.active div {
        background-color: var(--main-color);
      }

      &:not(.active) div:hover,
      &:not(.active) div:active,
      &:not(.active) div:focus {
        background-color: var(--main-color);
      }
      &:not(.active) div:focus {
        position: fixed;
      }
    }
  }

  .cn-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1;
    &.on-overlay {
      visibility: visible;
      opacity: 1;
    }
  }

  button {
    border: none;
    background: none;
    color: var(--main-bg);
    text-align: center;
    font-size: 1.8em;
    padding-bottom: 0.3em;
    height: 3.5em;
    width: 3.5em;
    background-color: var(--main-color);
    position: fixed;
    left: 50%;
    margin-left: -1.75em;
    bottom: -1.75em;
    border-radius: 50%;
    cursor: pointer;
    transform: scale3d(1, 1, 1);
    z-index: 3;
    
    svg {
      animation: ${hover} 1s linear infinite alternate;
    }
    

    @media ${device.mobileS} {
      height: 5.9em;
      width: 5.9em;
      margin-left: -3em;
      bottom: -3.2em;
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--sub-bg);

      outline: none;
    }
  }

  @media screen and (max-width: 480px) {
    .cn-wrapper {
      font-size: 0.68em;
    }

    .cn-button {
      font-size: 1.3em;
    }

    .cn-wrapper li {
      font-size: 1.52em;
    }
  }

  @media screen and (max-width: 320px) {
    .no-csstransforms .cn-wrapper {
      width: 15.15px;
      margin-left: -7.5em;
    }

    .no-csstransforms .cn-wrapper li {
      height: 3em;
      width: 3em;
    }
  }
`;

export default SwitchWrapper;
