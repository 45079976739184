import React from "react";

import styled from "styled-components";
import { PrimaryButton } from "../components/Button";
import P from "../components/Paragraph";

const ErrorWrap = styled.div`
  font-family: var(--main-font);
  text-align: center;
  width: 100%;

  h1 {
    font-size: 12vw;
    line-height: 2;
    background: radial-gradient(
      var(--heading-color) 10%,
      var(--main-color) 30%
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  h2 {
    font-size: 2.3rem;
    line-height: 1.5;
    background: radial-gradient(
      var(--heading-color) 10%,
      var(--main-color) 30%
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
`;

function Error() {
  return (
    <ErrorWrap>
      <h1>Oops!</h1>
      <h2>404 - PAGE NOT FOUND</h2>
      <P>
        The Page You're looking for is likely in another <span>dimension</span>
      </P>
      <PrimaryButton title="Return Home" top="2.5" link="/" tab="_self" />
    </ErrorWrap>
  );
}

export default Error;
