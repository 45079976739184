import styled from "styled-components";
import React from "react";
import { device } from "../utilities/Breakpoints";

import Css from "../assets/css.svg";
import Bootstrap from "../assets/bootstrap.svg";
import Django from "../assets/django.svg";
import Figma from "../assets/figma.svg";
import Html from "../assets/html.svg";
import Js from "../assets/js.svg";
import Scss from "../assets/scss.svg";
import ReactImg from "../assets/react.svg";
import MaterialUi from "../assets/materialui.svg";
import Python from "../assets/python.svg";

const Skill = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 6rem;
  perspective: 1000px;

  @media ${device.mobile} {
    //margin: auto;
    margin-top: 5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 8rem;
  }
  

  div {
    max-width: 5rem;
    transform-style: preserve-3d;
    
    @media ${device.tablet} {
      max-width: 4rem;
    }
    @media ${device.mobile} {
      margin: auto;
      max-width: 5rem;
    }

    img {
      width: 100%;
      height: auto;
      transition: all .6s; 
    }
    &:hover{
      img{
        z-index:2;
        transform: translateZ(30px);
      }
    }
  }
`;

function Skills() {
  return (
    <Skill>
      <div>
        <img src={Css} alt="CSS Skill" />
      </div>
      <div>
        <img src={Bootstrap} alt="Bootstrap Skill" />
      </div>
      <div>
        <img src={Django} alt="Django Skill" />
      </div>
      <div>
        <img src={Figma} alt="Figma Skill" />
      </div>
      <div>
        <img src={Html} alt="Html Skill" />
      </div>
      <div>
        <img src={Js} alt="Js Skill" />
      </div>
      <div>
        <img src={Scss} alt="Scss Skill" />
      </div>
      <div>
        <img src={ReactImg} alt="ReactImg Skill" />
      </div>
      <div>
        <img src={MaterialUi} alt="MaterialUi Skill" />
      </div>
      <div>
        <img src={Python} alt="Python Skill" />
      </div>
    </Skill>
  );
}

export default Skills;
