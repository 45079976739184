import styled from "styled-components";

const P = styled.p`
  font-size: var(--p-font);
  line-height: 2;
  font-family: var(--main-font);
  color: var(--text-color);
  margin-top: ${(props) => props.top}rem;
  margin-bottom: ${(props) => props.bottom}rem;

  span {
    color: var(--main-color);
  }
`;

export default P;
